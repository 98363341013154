import CategoryBox from "../../components/CategoryBox/CategoryBox"
import Row from "../../components/Row/Row"
import "./ReferencesPage.scss"

function ReferencesPage () {
    return <div className="ReferencesPage">
        <Row>
            <h1 className="page-title">Nos Références</h1>
        </Row>
       <Row
           type={'BoxRow'}>
         <CategoryBox
          imageUrl={'https://www.metatub.com/img/clients/client1.png'}
           />
        <CategoryBox
          imageUrl={'https://www.metatub.com/img/clients/client2.png'}
           />
           <CategoryBox
          imageUrl={'https://www.metatub.com/img/clients/client3.png'}
           />
           <CategoryBox
          imageUrl={'https://www.metatub.com/img/clients/client4.png'}
           />
           <CategoryBox
          imageUrl={'https://www.metatub.com/img/clients/client5.png'}
           />
        </Row>
    </div>
}

export default ReferencesPage