import { Grid } from '@mui/material'
import Row from '../../components/Row/Row'
import './ContactUsPage.scss'

function ContactUsPage() {
    return <div className="ContactUsPage">
        <Row>
            <h1 className="page-title">Contactez-Nous</h1>
            <h2 className="page-sub-title">Pour toute question, n’hésitez pas à contacter notre service commercial ou technique ou bien rendez-vous à notre showroom.</h2>
            <h2 className="page-sub-title">A réception de votre formulaire de demande de devis, notre service commercial ou technique pourra être amené à vous contacter.</h2>
        </Row>
        <Row>
            <Grid container spacing={2}>
                <Grid xs={5}>
                    <h3><i className="fa fa-envelope mr-2" aria-hidden="true"></i> E-mail</h3>
                    <p className="coordonnees">contact@metatub.com</p>
                    <h3><i className="fa fa-map-marker mr-2" aria-hidden="true"></i> Notre adresse</h3>
                    <p className="coordonnees"> 9, Rue de l'energie, ZI Ben Arous
                    </p><h3><i className="fa fa-phone mr-2" aria-hidden="true"></i> Nos numéros</h3>
                    <p className="mb-0">
                        <b>SERVICE COMMERCIAL:</b> (+216) 22 744 704
                        <br /><b>SERVICE ASSISTANCE:</b> (+216) 52 732 239
                    </p>
                </Grid>
                <Grid xs={7}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3935.585590902526!2d10.238835931945088!3d36.754038589851795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sfr!4v1670270767355!5m2!1sen!2sfr" className='iframe'></iframe>
                </Grid>
            </Grid> 
        </Row>
    </div>
}

export default ContactUsPage