import "./Footer.scss"

function Footer () {
    return <div className="Footer">
      <div className="footer-head">
      <div className="footer-div">
        <p className="text-center">Metatub est l'entité spécialisée dans la sous-traitance : cintrage des tubes, pliage et repoussage des tolês sur mesure en Acier, Inox et Aluminium.</p>
        <h3 className="h5 text-uppercase font-weight-normal">Follow-us</h3>
        <ul className="list-unstyled">
          <li><a className="p-0" href="https://www.facebook.com/metatub/" target="_blank" rel="noreferrer"><img height='60px' src="https://www.metatub.com/img/contact-us.png" alt="Facebook" /></a></li>
        </ul>
      </div>
      <div className="footer-div">
        <h3 className="h5 text-uppercase font-weight-normal">Information &amp; Services</h3>
        <ul className="list-unstyled footer-list">
          <li><a href="/">Accueil</a></li>
          <li><a href="/realisations">Réalisations</a></li>
          <li><a href="/refrences">Nos Références</a></li>
          <li><a href="/contact-us">Contactez-nous</a></li>
        </ul>
      </div>
      <div className="footer-div">
        <h3 className="h5 text-uppercase font-weight-normal">Contact</h3>
        <ul className="list-unstyled">
          <li><i className="fa fa-envelope mr-2" aria-hidden="true"></i> contact@metatub.com</li>
          <li><i className="fa fa-map-marker mr-2" aria-hidden="true"></i> <b>USINE:</b> 9, Rue de l'energie, ZI Ben Arous</li>
          <li><i className="fa fa-phone mr-2" aria-hidden="true"></i> (+216) 52 732 239 /  (+216) 22 744 704</li>
        </ul>
      </div>
      </div>
      <div className="footer-buttom">
        © 2018 - {new Date().getFullYear()} Copyright Metatub
      </div>
    </div>
}

export default Footer