import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomeLayout from './layouts/HomeLayout/HomeLayout';
import HomePage from './pages/HomePage/HomePage';
import ActivityLayout from './layouts/ActivityLayout/ActivityLayout';
import ReferencesPage from './pages/ReferencesPage/ReferencesPage'
// import AdminLayout from './layouts/AdminLayout/AdminLayout';
// import AdminHomePage from './pages/admin-pages/AdminHomePage/AdminHomePage';
// import AddActivity from './pages/admin-pages/AddActivity/AddActivity';
import ContactUsPage from './pages/ContactUsPage/ContactUsPage';
import RealisationsPage from './pages/RealisationsPage/RealisationsPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<HomePage />} />
            <Route path="activity" element={<ActivityLayout />} />
            <Route path='refrences' element={<ReferencesPage />} />
            <Route path="contact" element={<ContactUsPage />} />
            <Route path="realisations" element={<RealisationsPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
          {/* <Route path='/mhiri-admin' element={<AdminLayout layout='admin' />}>
            <Route index element={<AdminHomePage />} />
            <Route path='add' element={<AddActivity />} />
          </Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
