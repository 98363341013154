import "./Row.scss"

function Row (props: any) {
    const divStyle = {
        backgroundColor: props.color
    }

    return <div style={divStyle} className={`Row ${props.type}`}>
        {props.children}
    </div>
}

export default Row