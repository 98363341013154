import CategoryBox from "../../components/CategoryBox/CategoryBox"
import Row from "../../components/Row/Row"
import "./HomePage.scss"
import videoImage from '../../assets/img/banner.jpeg'
import bannerVideo from '../../assets/video/banner.mp4'
import enignting from '../../assets/img/enlighting.jpeg'
import cintrage from '../../assets/img/services/service4.jpg'
import repoussage from '../../assets/img/services/service1.jpg'
import usinageDiver from '../../assets/img/services/service8.jpg'
import pliage from '../../assets/img/services/service9.jpg'
import injectionAliminum from '../../assets/img/services/service10.jpg'
import injectionPlastique from '../../assets/img/services/service11.jpg'
import FerForge from '../../assets/img/services/service12.jpg'

import Client1 from '../../assets/img/clients/client1.png'
import Client2 from '../../assets/img/clients/client2.png'
import Client3 from '../../assets/img/clients/client3.png'
import Client4 from '../../assets/img/clients/client4.png'
import Client5 from '../../assets/img/clients/client5.png'

function HomePage () {
    return <div className="HomePage">
      <div className="homeVideoPlayer">
        <video preload='auto' poster={videoImage} autoPlay muted loop>
          <source src={bannerVideo} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <Row color={'#12141c'}>
        <h1 className="RowTitle">Nos Services</h1>
      </Row>
      <Row
        type={'BoxRow'}
        color={'#12141c'}>
        <CategoryBox
          imageUrl={cintrage}
          title={'Cintrage'}
           />
        <CategoryBox
          imageUrl={enignting}
          title={'Enlighten Your World'}
           />
        <CategoryBox
          imageUrl={repoussage}
          title={'Repoussage'}
           />
        <CategoryBox
          imageUrl={usinageDiver}
          title={'Usinage Divers'}
           />
        <CategoryBox
          imageUrl={pliage}
          title={'Pliage'}
           />
        <CategoryBox
          imageUrl={injectionPlastique}
          title={'Injection Plastique Thermodurcissable'}
           />
        <CategoryBox
          imageUrl={injectionAliminum}
          title={'Injection Aluminum'}
           />
        <CategoryBox
          imageUrl={FerForge}
          title={'Fer Forgé'}
           />
      </Row>
      <Row
        color={'#1D4354'}>
        <h1 className="RowTitle">Domaines d'Activités</h1>
        <h2 className="RowSubTitle">Metatub est l'entité spécialisée dans la sous-traitance : cintrage des tubes et repoussage des tolês sur mesure en acier, inox et aluminium.</h2>
        <h2 className="RowSubTitle">MULTI SECTEURS Nous intervenons dans tous les secteurs de l'économie : agro-alimentaire, chimie, pétrochimie, bâtiment, travaux publics, construction navale, transport pneumatique…</h2>
      </Row>
      {/* Nos Reference */}
      <Row
        color={'#08212d'}>
        <h1 className="RowTitle">Nos Références</h1>
      </Row>
      <Row
        type={'ReferencesBoxes'}
        color={'#08212d'}>
          <CategoryBox
            type={'Reference'}
            imageUrl={Client1}
            alt={'General Metal'}
           />
        <CategoryBox
            type={'Reference'}
            imageUrl={Client2}
            alt={'Coca Cola'}
           />
           <CategoryBox
            type={'Reference'}
            imageUrl={Client3}
            alt={'OACA, Office de l\'avation civile et des aeroports'}
           />
           <CategoryBox
            type={'Reference'}
            imageUrl={Client4}
            alt={'Comet'}
           />
           <CategoryBox
            type={'Reference'}
            imageUrl={Client5}
            alt={'Groupe Poulina'}
           />
      </Row>

    </div>
}

export default HomePage