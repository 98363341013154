import "./ActivityLayout.scss"

function ActivityLayout (props: any) {
    return <div className="ActivityLayout">
        <div className="left-side">
            <div className="breadcrumb">
                <a href="index.php" title="Accueil">Accueil</a> /
                <a href="activites.php" className="active" title="Activités">Activités</a>
                <a href="cintrage-de-profiles.php" className="active" title="Cintrage de profilés">Cintrage de profilés</a>
            </div>
            <h1 className="titre_main border">Cintrage de tubes métalliques</h1>
            <h2 className="chapo">Nos cintrages de tubes sont exécutés « à froid » sur des machines à commande numériques bénéficiant des derniers progrès technologiques. Nous possédons également un très vaste choix d’outillages pour le cintrage des tubes en acier ou dans d’autres alliages de dimension standard et hors standard.</h2>
            <div className="photo_page"><img src="../images/photo-01.jpg" alt="Machine cintrage tube metallique usine Belgique" /></div>
            <div className="bloc_texte">
            <h3 className="sous_titre">Différents types de cintrages possibles pour tous vos tubes métalliques</h3>
            <p>À la demande de nos clients, nous sommes en mesure de créer des outillages très spécifiques pour le cintrage de tubes de sections spéciales (par exemple : ovale, polygonale, carré, rectangulaire, oblong, elliptique, triangulaire, tube à ailettes, etc.).</p>
            <p>Nos machines performantes, associées à notre savoir-faire, nous offrent la possibilité de réaliser des pièces cintrées complexes, comme des structures tubulaires à plusieurs cintrages et en 3 dimensions, des spirales, des hélicoïdes ou toutes autres formes (2D ou 3D) à rayon constant ou variable.</p>
            <p>Nos techniques et nos outillages s’adaptent parfaitement à tous les métaux et leurs alliages cintrables (acier, inox, aluminium, cuivre, titane, inox, laiton, Hastelloy, Inconel, Incoloy, …). Notre structure souple est adaptée pour la mise en fabrication de quantités très variables, depuis la pièce unique (prototype) jusqu’à la grande série.</p>
            <h3 className="sous_titre">Applications – Quelques exemples de cintrage de tubes parmi d’autres :</h3>
            <ul><li>Cintrage de tubes ronds du Ø 3 mm jusqu’au Ø 323 mm (12 pouces)</li>
            <li>Cintrage sur presse</li>
            <li>Coude à rayon court (min.1,25 x DN)</li>
            <li>Cintrages coude sur coude (par exemple : plusieurs cintrages successifs sur la même pièce et avec des orientations différentes)</li>
            <li>Façonnage de serpentins cylindriques, coniques, rectangulaires, plats, faisceaux tubulaires, etc. ...</li>
            <li>Coudes pour le transport pneumatique</li>
            <li>Garde-corps, mains-courantes et portail tubulaires cintrés</li>
            <li>Éléments de protection tubulaire et barrière de sécurité</li>
            <li>Rampes et limons tubulaires pour escaliers tournants</li>
            <li>Coudes pour échappements</li>
            <li>Cintrage de tubes à paroi mince</li></ul>
            <h3 className="sous_titre">Secteurs d’activités – Quelques exemples parmi d’autres :</h3>
            <ul><li>Installations industrielles (chimie, pétrochimie, pharmaceutique, alimentaire, etc.)</li>
            <li>Machines et équipements agricoles</li>
            <li>Secteur ferroviaire, naval, militaire, nucléaire, énergétique</li>
            <li>Construction métallique</li>
            <li>Fabricant de mobilier métallique</li>
            <li>Ferronnerie de bâtiment</li>
            <li>Équipements automobiles et engins de chantier</li>
            <li>Fabrication d’équipements de sécurité et de protection</li>
            <li>Fabricant de chaudières, production de chaleur, production du froid</li>
            <li>Systèmes de transport pneumatique</li></ul>
            <p>Pour tout renseignement complémentaire sur le cintrage de tubes, n’hésitez pas <a href="contact.php" title="nous contacter">nous contacter</a> ! Consultez également <a href="galerie.php" title="nos réalisations">nos réalisations</a>.</p>
            <h3 className="sous_titre">Nos autres services :</h3>
            <ul><li><a href="cintrage-de-profiles.php" title="Cintrage de profilés">Cintrage de profilés</a></li>
            <li><a href="faconnage-grugeage-piquage-tubes.php" title="Usinage divers">Usinage divers</a></li>
            <li><a href="soudure-brasure-tube-tuyau.php" title="Soudure">Soudure</a></li>
            </ul>
            </div>
        </div>
        <div className="rightside">
            <div className="activity-list">
                <ul>
                    <li className="activity-title">Activities</li>
                    <li>Cintrage</li>
                    <li>Repoussage</li>
                    <li>Usinage Divers</li>
                    <li>Pliage</li>
                    <li>Injection Plastique Thermodurcissable</li>
                    <li>Injection Aluminum</li>
                </ul>
            </div>
        </div>
    </div>
}

export default ActivityLayout