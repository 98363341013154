import { Outlet } from "react-router-dom"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import "./HomeLayout.scss"

function HomeLayout (props: any) {
    return <div className="HomeLayout">
        <Header />
        <Outlet />
        <Footer />
    </div>
}

export default HomeLayout