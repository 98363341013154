import "./CategoryBox.scss"

function CategoryBox (props: any) {
    const onclick = () => {
        window.location.href = '/activity'
    }

    // onClick={onclick}
    return <div className={`CategoryBox ${props.type}`} >
        <img src={props.imageUrl} alt={props.alt ? props.alt : ''} />
        {props.title && <h2 className="title">{props.title}</h2>}
    </div>
}

export default CategoryBox