import CategoryBox from '../../components/CategoryBox/CategoryBox'
import Row from '../../components/Row/Row'
import './RealisationsPage.scss'

function RealisationsPage() {
    const boxs = []
    for(let i = 1; i < 45; i++) {
        boxs.push(<CategoryBox imageUrl={require(`../../assets/img/realisations/${i}.jpeg`)} />)
    }

    return <div className="RealisationsPage">
        <Row>
            <h1 className="page-title">Nos Réalisation</h1>
        </Row>

        <Row
           type={'BoxRow'}>
        { boxs }
        </Row>
    </div>
}

export default RealisationsPage