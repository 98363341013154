import "./Header.scss"
import metatubRmr from "../../assets/img/metatub-rmr.png"

function Header (props: any) {
    return <header className="Header">
      <section className="header-details">
        <span className="langue">
          <span>FR</span><em> - </em>
          <span>EN</span><em> - </em>
          <span>AR</span>
        </span>
        <span className="contact-details">
          <a href="mailto:contact@metatub.com" className="mail_top">contact@metatub.com</a>
          <span> | </span>
          <span className="phone_top"><a href="tel:+21622744704">(+216) 22 744 704</a> - <a href="tel:+21652732239">(+216) 52 732 239</a></span>
        </span>
      </section>
      <div className="header-top">
        <div className="header-logo">
          <a href="/"><img src={metatubRmr} alt="" height="50px" /></a>
        </div>
        <div className="header-bar">
          <a href="/" className="bar-item">Accueil</a>
          <a href="/realisations" className="bar-item">Réalisation</a>
          <a href="/refrences" className="bar-item">Nos Références</a>
          <a href="/contact" className="bar-item">Contactez-nous</a>
        </div>
      </div>
    </header>
}

export default Header